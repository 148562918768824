#a_tabbar {
  .role-select-bt {
    width: 257px;
    margin: 10px 5px 0 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

html,
body {
  width: 100%;
  height: 100%;
}
.login-container {
  width: 100%;
  height: 100%;
  position: relative;
  .login-center-title-image {
    position: absolute;
    top: 10%;
    left: 18.75%;
    height: 5.8%;
  }
  .login-center-image {
    height: 75%;
    position: absolute;
    bottom: 0;
    left: 7.9%;
  }
}
.login-container-image {
  position: relative;
  width: 100%;
  height: 100%;
}
.layui-form {
  position: absolute;
  z-index: 10;
  left: 64%;
  top: 34%;
  width: 276px;
  min-height: 230px;
  background: #ffffff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  padding: 42px 32px;
  .login-form-title {
    padding: 0px 100px;
    margin-bottom: 20px;
    width: 74px;
    height: 25px;
    font-size: 18px;
    font-weight: 500;
    color: #687078;
    line-height: 25px;
  }
  .layui-form-item {
    position: relative;
    box-sizing: border-box;
    width: 276px;
    height: 40px;
    border-radius: 2px;
    margin: 0 auto 16px auto;
    .input-icon {
      position: absolute;
      width: 20px;
      height: 20px;
      top: 10px;
      left: 8px;
    }
    input {
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      padding: 0 20px 0 35px;
      outline: none;
    }
  }
  .layui-btn-sm {
    margin-top: 16px;
    width: 276px;
    height: 40px;
    background: #008bff;
    border-radius: 2px;
  }
}

.validate-item {
  padding-right: 95px;
}
.validate-right {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  box-sizing: border-box;
}
.validate-img {
  width: 95px;
  height: 100%;
}

.login-beian {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: #ffffff;
  color: rgba(255, 255, 255, 0.6);
  line-height: 20px;
  padding: 20px 0;
  a {
    color: inherit;
    text-decoration: none;
  }
}
